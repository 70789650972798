import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import eye from "../../../Components/assets/icons/eye.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { FaEye } from "react-icons/fa";
import ExportMethod from "../../../utils/apis/ExportMethod";
import { formatDate } from "../../../utils/common";

import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    IMG_URL,
    Per_Page_Dropdown,
    Select2Data,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchByUser, setSearchByUser] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchChildSubCategory, setSearchChildSubCategory] = useState("");
  const [totalPages, settotalPages] = useState();

  const [onPageChange, setonPageChange] = useState(1);

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const { id } = useParams();

  useEffect(() => {
    setSearchByUser(id || null); // Set to null if id is undefined or null
    getDataAll();
  }, [id]);

  useEffect(() => {
    getDataAll();
  }, [searchByUser]);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const queryString = `/product?page=${onPageChange}&per_page=${perPage}&term=${search}&searchByUser=${
      id || ""
    }&searchCategory=${searchCategory?.value || ""}&searchSubCategory=${
      searchSubCategory?.value || ""
    }&searchChildSubCategory=${searchChildSubCategory?.value || ""}`;
    const response = await getData(queryString);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    settotalPages(response?.data?.totalPages);

    console.log("response", response);
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    // search,
    // searchCategory,
    // searchSubCategory,
    // searchChildSubCategory,
    onPageChange,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/product/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/product/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);
    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);
    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "child_sub_category_id")
      );
    }
  };

  useEffect(() => {
    GetAllCategory();
  }, []);

  console.log("userData", user);
  const handleExportExcel = () => {
    const header = [
      { name: "Product Name" },
      { name: "Seller" },
      { name: "Category" },
      { name: "Sub Category" },
      { name: "Country" },
      { name: "HSN Code" },
      { name: "Brand" },
      { name: "Material" },
      { name: "Occasion" },
      { name: "Manufacturer" },
      { name: "Vendor" },
      { name: "Gifting Short Description " },
      { name: "Customize Price" },
      { name: "Tax Type" },
      { name: "Tax Percentage" },
      { name: "Product Description" },
      { name: "Product Care" },
      { name: "Delivery & Returns" },
      { name: "Product Variant Name" },
      { name: "MRP" },
      { name: "Selling Price" },
      { name: "Stock Unit" },
      { name: "SKU" },
    ];

    const exportData = [];

    // Loop through each product
    data?.data?.data?.forEach((d) => {
      // Extract material and occasion names
      const materialNames =
        d.product_detail.product_materials
          ?.map((material) => material.material.name)
          .join(", ") || "N/A";
      const occasionNames =
        d.product_detail.product_occasions
          ?.map((occasion) => occasion.occasion.name)
          .join(", ") || "N/A";

      // Track whether we've added the product details (only once per product)
      let productDetailsAdded = false;

      // Loop through each variant and add a row for each
      d.product_variants?.forEach((variant, index) => {
        exportData.push([
          productDetailsAdded ? "" : d.name, // Product Name (only for the first variant)
          productDetailsAdded ? "" : d.user.first_name + " " + d.user.last_name, // Seller Name (only for the first variant)
          productDetailsAdded ? "" : d.category?.name, // Category (only for the first variant)
          productDetailsAdded ? "" : d.sub_category?.name, // Sub Category (only for the first variant)
          productDetailsAdded ? "" : d.country?.name,
          productDetailsAdded ? "" : d.hsn_code, // Country (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.brand?.name, // Brand (only for the first variant)
          productDetailsAdded ? "" : materialNames, // Material (only for the first variant)
          productDetailsAdded ? "" : occasionNames, // Occasion (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.manufacturer, // Manufacturer (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.vender, // Vendor (only for the first variant)
          productDetailsAdded ? "" : d.short_description, // Gift Price (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.customize_price, // Customize Price (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.tax_type?.name, // Tax Type (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.tax_percentage?.name, // Tax Percentage (only for the first variant)
          productDetailsAdded ? "" : d.product_detail?.about,
          productDetailsAdded ? "" : d.product_detail?.benifits,
          productDetailsAdded ? "" : d.product_detail?.storage, // Product Description (only for the first variant)
          variant.v_name, // Product Variant Name
          variant.mrp, // MRP
          variant.s_price, // Selling Price
          variant.unit,
          variant.sku, // Stock Unit
        ]);

        // After the first variant, mark product details as added
        productDetailsAdded = true;
      });
    });

    // Export data as Excel
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "product"
    );

    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Products"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-3">
                <div className="">
                  {/* container */}
                  <div className="row">
                    {user?.id !== 1 ? (
                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-2 mt-2">
                        <div className="add me-3">
                          <Link
                            // to="/employee/employee_details/add"
                            type="button"
                            className="btn btn-add pe-3"
                          >
                            <div onClick={() => handleShow()}>
                              <p className="add-sub-txttt">
                                {" "}
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Product
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-2 mt-2">
                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Name
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Gift Wrap Price{" "}
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Total Variants
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Image
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Manufacturer
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            {user?.id !== 1 && (
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col8")
                                  }
                                  href="#"
                                >
                                  Status
                                  {visible?.col8 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col9")}
                                href="#"
                              >
                                {user?.id === 1 ? "View " : "Action "}
                                {visible?.col9 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-2 mt-2">
                      <button
                        className="btn btn-columns dropdown-toggle"
                        tabindex="0"
                        aria-controls="DataTables_Table_0"
                        type="button"
                        onClick={handleExportExcel}
                      >
                        <span>Excel Export</span>
                      </button>
                    </div>

                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-4 col-12">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} entries`}</p>
                        </div>
                      </div>
                      <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-5 col-12 mt-lg-0  mt-md-0 mt-3">
                        <div className="num me-2">
                          <Select
                            isSearchable
                            options={categories}
                            value={searchCategory}
                            placeholder="Select Category"
                            onChange={(e) => {
                              setSearchCategory(e);
                              GetAllSubCategory(e.value);
                              setSearchSubCategory("");
                              setSearchChildSubCategory("");
                              setonPageChange(1);
                            }}
                            classNamePrefix="searchProduct"
                          />
                        </div>
                      </div>
                      <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-5 col-12 mt-lg-0 mt-md-2 mt-3">
                        <div className="num me-2">
                          <Select
                            isSearchable
                            options={subCategories}
                            value={searchSubCategory}
                            placeholder="Select Sub Category"
                            onChange={(e) => {
                              setSearchSubCategory(e);
                              GetAllChildSubCategory(e.value);
                              setSearchChildSubCategory("");
                              setonPageChange(1);
                            }}
                            classNamePrefix="searchProduct"
                          />
                        </div>
                      </div>
                    
                      <div className="col-xxl-2 col-xl-2  col-lg-4  col-md-4 col-12 mt-lg-0 mt-md-2 mt-3">
                        <div className="Search me-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            id=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setonPageChange(1);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xxl-1 col-xl-1  col-lg-1  col-md-1 col-12 mt-lg-0 mt-md-2 mt-3">
                        <div className="Search me-2">
                          <Button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </Button>
                        </div>
                      </div>
                      <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-3  mt-lg-0">
                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setSearchCategory("");
                              setSearchSubCategory("");
                              setSearchChildSubCategory("");
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>

                      {/* 
                        </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Name
                              </th>
                            )}

                            {visible.col4 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Category
                              </th>
                            )}

                            {visible.col10 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Sub Category
                              </th>
                            )}
                            {visible.col5 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Total Variants
                              </th>
                            )}
                            {visible.col6 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Image
                              </th>
                            )}
                            {visible.col7 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Manufacturer
                              </th>
                            )}
                            {/* {visible.col6 && (
                            <th className="tax-name">Password</th>
                          )} */}
                            {visible.col8 && user?.id !== 1 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Status
                              </th>
                            )}
                            {visible.col9 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                {user?.id === 1 ? "View " : "Action "}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col2 && <td>{d?.name}</td>}

                                {visible.col4 && <td>{d?.category?.name}</td>}
                                {visible.col10 && (
                                  <td>{d?.sub_category?.name}</td>
                                )}
                                {visible.col5 && (
                                  <td>{d?.product_variants.length}</td>
                                )}
                                {visible.col6 && (
                                  <td>
                                    {d?.image1 && (
                                      <img
                                        src={IMG_URL + d?.image1}
                                        alt="Image"
                                        width="100"
                                        height="100"
                                      />
                                    )}
                                  </td>
                                )}
                                {visible.col7 && (
                                  <td>{d?.product_detail?.manufacturer}</td>
                                )}
                                {/* {visible.col6 && <td>{d?.password}</td>} */}
                                {/* {visible.col6 && <td>******</td>} */}
                                {visible.col8 && user?.id !== 1 && (
                                  <td>
                                    {d?.id !== 1 &&
                                      (d?.stage === "Approved" ? (
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={d.status}
                                            // disabled={!isAllow?.includes(89)}
                                            onChange={() => {
                                              ChangeStatus(d.id);
                                            }}
                                            id={`flexSwitchCheckDefault${d?.id}`}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                          >
                                            {d.status ? "Active" : "Inactive"}
                                          </label>
                                        </div>
                                      ) : (
                                        <p>{d?.stage}</p>
                                      ))}
                                  </td>
                                )}

                                {/* {visible.col10 && user?.id !== 1 && (
                                  <td>
                                    {d?.id !== 1 && (
                                      d?.stage === "Approved" ? (
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={d.customize_status}
                                            // disabled={!isAllow?.includes(89)}
                                            onChange={() => {
                                              ChangeStatus(d.id);
                                            }}
                                            id={`flexSwitchCheckDefault${d?.id}`}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                          >
                                            {d.status ? "Active" : "Inactive"}
                                          </label>
                                        </div>
                                      ) : (
                                        <p>{d?.stage}</p>
                                      )
                                    )}
                                  </td>
                                )} */}
                                {visible.col9 && (
                                  <td>
                                    {/* {d?.id === 1 ? (
                                      <></>
                                    ) : ( */}
                                    <div className="d-flex">
                                      {/* {isAllow.includes(89) ? ( */}
                                      <Button
                                        onClick={() => handleShow1(d?.id)}
                                        type="button"
                                        className="btn btn-primary me-1"
                                      >
                                        {/* {user?.id === 1 ? (
                                          <img
                                            src={eye}
                                            className="pen"
                                            alt=""
                                          />
                                        ) : ( */}
                                        <img src={pen} className="pen" alt="" />
                                        {/* )} */}
                                      </Button>
                                      {/* ) : (
                                        <></>
                                      )} */}

                                      {user?.id !== 1 ? (
                                        <button
                                          onClick={() => {
                                            showDeleteRecord(d?.id, d?.name);
                                          }}
                                          type="button"
                                          className="btn btn-danger"
                                        >
                                          <img
                                            src={basket}
                                            className="pen"
                                            alt=""
                                          />
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {/* )} */}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
