import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import CupponCodes from "./Components/Masters/CupponCodes/Tables";

import SellerDetails from "./Components/sellerDetails/Table";

import Role from "./Components/EmployeeManagement/Role/Table";
import EmployeeDetails from "./Components/EmployeeManagement/EmployeeDetails/Table";
import EmployeeRequest from "./Components/EmployeeManagement/EmployeeRequests/Table";
import EmployeeRejected from "./Components/EmployeeManagement/EmployeeRejected/Table";
import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";
import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";
import Subscriber from "./Components/CustomerManagement/Subscriber/Tables";
import MadeToOrder from "./Components/CustomerManagement/MadeToOrder/Tables";
import TalkToUs from "./Components/CustomerManagement/TalkToUs/Tables";
import RFQ from "./Components/CustomerManagement/RFQ/Tables";
import Suggestion from "./Components/CustomerManagement/Suggestion/Tables";
import Responsibility from "./Components/CustomerManagement/Responsibility/Tables";
import JobsResponse from "./Components/CustomerManagement/Jobs/Tables";
import ProductQuery from "./Components/CustomerManagement/ProductQuery/Tables";
import Customizeform from "./Components/CustomerManagement/customizeform/Tables";

import Product from "./Components/Products/Product/Table";
import ProductRequests from "./Components/Products/ProductRequests/Table";
import ProductRejected from "./Components/Products/ProductRejected/Table";
import WhoWeAre from "./Components/AboutUs/WhoWeAre/Tables";
import Values from "./Components/AboutUs/Values/Tables";
import OurStory from "./Components/AboutUs/OurStory/Tables";
import OurLatestAward from "./Components/AboutUs/OurLatestAward/Tables";
import ProductType from "./Components/Products/ProductType/Table";
import Orders from "./Components/OrderManagments/Orders/Table";
import OrdersToRefund from "./Components/OrderManagments/OrderToRefund/Table";
import ReturnOrders from "./Components/OrderManagments/ReturnOrders/Table";
import CustomeOrders from "./Components/OrderManagments/CustomOrder/Table";
// Settings 
// Categories
import Category from "./Components/Masters/Category/Tables"
import SubCategory from "./Components/Masters/Sub_Category/Tables"
import ChildSubCategory from "./Components/Masters/Child_Sub_Category/Tables"
import Attributes from "./Components/Masters/Attribute/Tables"
import SubAttributes from "./Components/Masters/SubAttribute/Tables"

// Customization 
import WebHeader from "./Components/Masters/WebHeader/Tables"
import AppSetup from "./Components/Masters/AppSetup/Tables"
import HomeBanner from "./Components/Masters/home_Banner/Tables"
import HomeAdBanner from "./Components/Masters/Ad_Banner/Tables"
import Blogs from "./Components/Masters/Blogs/Tables"
import FaqCategory from "./Components/Masters/FaqCategory/Tables"
import Faq from "./Components/Masters/FAQs/Tables"
import SocialMedia from "./Components/Masters/SocialMedia/Tables"

import CouponCodes from "./Components/Masters/CouponCodes/Table"
import Brands from "./Components/Masters/Brands/Tables"
import WebsiteType from "./Components/Masters/WebsiteType/Tables"
import Occasion from "./Components/Masters/Occasion/Tables"
import Material from "./Components/Masters/Material/Tables"
import ClothSize from "./Components/Masters/ClothSize/Tables"
import CustomerLove from "./Components/Masters/customer_love/Tables"

import AboutUsCounter from "./Components/Masters/AboutUsCounter/Tables"

// Location 
import Country from "./Components/Masters/Country/Tables"
import State from "./Components/Masters/State/Tables"
import City from "./Components/Masters/City/Tables"
import Pincode from "./Components/Masters/Pincode/Tables"
import CountryCodes from "./Components/Masters/CountryCode/Tables"

// Tax 
import TaxType from "./Components/Masters/Tax_Types/Tables"
import TaxPercentage from "./Components/Masters/Tax_Percentage/Tables"

import HelpUs from "./Components/Masters/HelpUs/Tables"

import PointSetup from "./Components/Masters/PointSetup/Tables"
import Offers from "./Components/Masters/Offers/Tables"
import HomeOffers from "./Components/Masters/HomeOffers/Tables"
import BlogOffers from "./Components/Masters/BlogOffers/Tables"
import PrivacyPolicy from "./Components/Masters/PrivacyPolicy/Tables"
import LegalNotice from "./Components/Masters/LegalNotice/Tables"
import ResponsibilityPolicy from "./Components/Masters/Resposibility/Tables"
import LatestNews from "./Components/Masters/LatestNews/Tables"
import ProductGiftWrapPrice from "./Components/Masters/ProductGiftWrapPrice/Edit"
import ProductReview from "./Components/Masters/ProductReview/Tables"
import Notification from "./Components/Masters/Notification/Tables"
// Seller
// Career
import WorkModule from "./Components/Career/Language/Table";
import JobType from "./Components/Career/JobType/Table";
import Department from "./Components/Career/Shift/Table";
import Qualification from "./Components/Career/Qualification/Table";
import Jobs from "./Components/Career/Jobs/Tables";

import PageNotFound from "./Components/Masters/PageNotFound/PageNotFound";
// import { Context } from "./utils/context";
import { IDS } from "./utils/common";
const App = () => {
  const location = useLocation();
  const { isSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);
  const { isAllow } = useContext(Context);
  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/advanceDashboard",
        element: <AdvanceDashboard />,
        status: true,
      },
      {
        path: "/settings/location/country",
        element: <Country />,
        status: IDS.Country.some((value) => isAllow.includes(value)),
      },
     
      {
        path: "/settings/location/state",
        element: <State />,
        status: IDS.State.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/location/city",
        element: <City />,
        status: IDS.City.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/location/pincode",
        element: <Pincode />,
        status: IDS.Pincode.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/category",
        element: <Category />,
        status: IDS.Category.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/sub-category",
        element: <SubCategory />,
        status: IDS.SubCategory.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/website-type",
        element: <WebsiteType />,
        status: IDS.WebsiteType.some((value) => isAllow.includes(value)),
      },
      

      {
        path: "/career/work-module",
        element: <WorkModule />,
        status: IDS.Language.some((value) => isAllow.includes(value)),
      },
      {
        path: "/career/job-type",
        element: <JobType />,
        status: IDS.JobType.some((value) => isAllow.includes(value)),
      },
      {
        path: "/career/department",
        element: <Department />,
        status: IDS.Shift.some((value) => isAllow.includes(value)),
      },
      {
        path: "/career/qualification",
        element: <Qualification />,
        status: IDS.Qualification.some((value) => isAllow.includes(value)),
      },
      {
        path: "/career/jobs",
        element: <Jobs />,
        status: IDS.Job.some((value) => isAllow.includes(value)),
      },
      {
        path: "/order-managements/order",
        element: <Orders />,
        status: IDS.Order.some((value) => isAllow.includes(value)),
      },
      {
        path: "/order-managements/order/return",
        element: <ReturnOrders />,
        status: IDS.ReturnedOrder.some((value) => isAllow.includes(value)),
      },


      {
        path: "/product",
        element: <Product />,
        status: IDS.Product.some((value) => isAllow.includes(value)),
      },
      {
        path: "/product/:id",
        element: <Product />,
        status: IDS.Product.some((value) => isAllow.includes(value)),
      },
      {
        path: "/product-type",
        element: <ProductType />,
        status: IDS.ProductType.some((value) => isAllow.includes(value)),
      },
      {
        path: "/product-requests",
        element: <ProductRequests />,
        status: IDS.ProductRequest.some((value) => isAllow.includes(value)),
      },
      {
        path: "/product-rejected",
        element: <ProductRejected />,
        status: IDS.ProductReject.some((value) => isAllow.includes(value)),
      },

      {
        path: "/settings/material",
        element: <Material />,
        status: IDS.Material.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/occasion",
        element: <Occasion />,
        status: IDS.Occasion.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/cloth-size",
        element: <ClothSize />,
        status: IDS.ClothSize.some((value) => isAllow.includes(value)),
      },

      {
        path: "/seller-detail",
        element: <EmployeeDetails />,
        status: IDS.SellerDetails.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/employee-requests",
        element: <EmployeeRequest />,
        status: IDS.SellerRequest.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/employee-rejected",
        element: <EmployeeRejected />,
        status: IDS.SellerRejected.some((value) => isAllow.includes(value)),
      },
      

      {
        path: "/employee/customer-details",
        element: <CustomerDetails />,
        status: IDS.CustomerDetails.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/contact-us",
        element: <ContactUs />,
        status: IDS.ContactUs.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/subscriber",
        element: <Subscriber />,
        status: IDS.ContactUs.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/customizeform",
        element: <Customizeform />,
        status: IDS.Customization.some((value) => isAllow.includes(value)),
      },

      {
        path: "/employee/made-to-order",
        element: <MadeToOrder />,
        status: IDS.MadeTorder.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/responsibility",
        element: <Responsibility />,
        status: IDS.ResponsibilityRequest.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/suggestion",
        element: <Suggestion />,
        status: IDS.Suggestion.some((value) => isAllow.includes(value)),
      },

      {
        path: "/employee/rfq",
        element: <RFQ />,
        status: IDS.RFQ.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/talk-to-us",
        element: <TalkToUs />,
        status: IDS.TalkToUs.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/product-query",
        element: <ProductQuery />,
        status: IDS.ProductCare.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/jobs",
        element: <JobsResponse />,
        status: IDS.JobRequest.some((value) => isAllow.includes(value)),
      },



      {
        path: "/latest-news",
        element: <LatestNews />,
        status: IDS.LatestNews.some((value) => isAllow.includes(value)),
      },
      {
        path: "/responsibility",
        element: <ResponsibilityPolicy />,
        status: IDS.Responsibility.some((value) => isAllow.includes(value)),
      },
      {
        path: "/legal-notice",
        element: <LegalNotice />,
        status: IDS.LegalNotice.some((value) => isAllow.includes(value)),
      },

      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
        status: IDS.PrivacyPolicy.some((value) => isAllow.includes(value)),
      },
      {
        path: "/blog-offer",
        element: <BlogOffers />,
        status: IDS.BlogOffer.some((value) => isAllow.includes(value)),
      },
      {
        path: "/home-offer",
        element: <HomeOffers />,
        status: IDS.HomeOffer.some((value) => isAllow.includes(value)),
      },

      {
        path: "/offer",
        element: <Offers />,
        status: IDS.Offer.some((value) => isAllow.includes(value)),
      },
      {
        path: "/help-us",
        element: <HelpUs />,
        status: IDS.HelpUs.some((value) => isAllow.includes(value)),
      },
      {
        path: "/point-setup",
        element: <PointSetup />,
        status: IDS.PointSetup.some((value) => isAllow.includes(value)),
      },

      {
        path: "/app-setup",
        element: <AppSetup />,
        status: IDS.PointSetup.some((value) => isAllow.includes(value)),
      },



      {
        path: "/settings/customer-love",
        element: <CustomerLove />,
        status: IDS.CustomerLove.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/customization/faqs",
        element: <Faq />,
        status: IDS.FAQ.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/customization/faq-category",
        element: <FaqCategory />,
        status: IDS.FAQCategory.some((value) => isAllow.includes(value)),
      },

      {
        path: "/settings/social-media",
        element: <SocialMedia />,
        status: IDS.SocialMedia.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/customization/blogs",
        element: <Blogs />,
        status: IDS.Blog.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/role",
        element: <Role />,
        status: IDS.Roles.some((value) => isAllow.includes(value)),
      },

      {
        path: "/settings/customization/home-banner",
        element: <HomeBanner />,
        status: IDS.HomeBanner.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/customization/home-ad-banner",
        element: <HomeAdBanner />,
        status: IDS.OfferAdvertisement.some((value) => isAllow.includes(value)),
      },


      {
        path: "/about-us-counter",
        element: <AboutUsCounter />,
        status: IDS.AboutUsCounter.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/brands",
        element: <Brands />,
        status: IDS.Brands.some((value) => isAllow.includes(value)),
      },
      {
        path: "/about-us/our-latest-award",
        element: <OurLatestAward />,
        status: IDS.OurLatestAward.some((value) => isAllow.includes(value)),
      },

      {
        path: "/about-us/values",
        element: <Values />,
        status: IDS.Values.some((value) => isAllow.includes(value)),
      },
      {
        path: "/about-us/who-we-are",
        element: <WhoWeAre />,
        status: IDS.WhoWeAre.some((value) => isAllow.includes(value)),
      },

      {
        path: "/settings/tax/tax-type",
        element: <TaxType />,
        status: IDS.TaxType.some((value) => isAllow.includes(value)),
      },
      {
        path: "/settings/tax/tax-percentage",
        element: <TaxPercentage />,
        status: IDS.TaxPercentage.some((value) => isAllow.includes(value)),
      },
      {
        path: "/master/product-gift-wrap_price",
        element: <ProductGiftWrapPrice />,
        status: IDS.TaxPercentage.some((value) => isAllow.includes(value)),
      },
      {
        path: "/master/product-review",
        element: <ProductReview />,
        status: IDS.TaxPercentage.some((value) => isAllow.includes(value)),
      },
      {
        path: "/master/notification",
        element: <Notification />,
        status: IDS.TaxPercentage.some((value) => isAllow.includes(value)),
      },
      
    
    ]);
  }, [isAllow]);
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className=" d-flex sidebar-x-scroll">
          <div
            className={`${isSidebarOpen
              ? "sidebarHeadopen "
              : "sidebarHeadClose"
              }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${isSidebarOpen
              ? "dashboradopen"
              : "dashboradopen"
              }  ps-0`}
          >
            <div className="allRoutesMain">
                         
              <Routes>
                <Route path="/" element={<Login />} />
                {urls?.map(
                  (url) => url.status && <Route path={url.path} element={url.element} />
                )}
                {/* <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} /> */}
                {/* PageNotFound Start  */}
                <Route path="*" element={<PageNotFound />} /> {/* PageNotFound End  */}
              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
