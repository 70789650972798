import React, { useContext } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);
  const [imageFile, setImageFile] = useState(null);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const sendData = new FormData();
      sendData.append("name", data?.name);
      sendData.append("description", data?.description);
      sendData.append("logo", data?.logo[0]);
      const response = await postData(`/about-us/who-we-are`, sendData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [imagePreview, setImagePreview] = useState(null); // State to store image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile([file]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    } else {
      setImageFile(null);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Who We Are
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Name</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        {...register("name", {
                          required: "Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-start">
                    {" "}
                    {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description"
                          control={control}
                          rules={{
                            required: "Description is required.",
                            // maxLength: {
                            //   value: 600,
                            //   message:
                            //     "Description must be at most 600 characters long.",
                            // },
                          }} // Correct the maxLength rule
                          render={({ field: { onChange, onBlur, value } }) => (
                            <JoditEditor
                              value={value}
                              onBlur={() => {
                                onBlur();
                                trigger("description");
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Description</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Description"
                        className={classNames("", {
                          "is-invalid": errors?.description,
                        })}
                        {...register("description", {
                          required: "Description is required",
                        })}
                      />
                    </InputGroup>
                    {errors.description && (
                      <span className="text-danger">
                        {errors.description.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Logo</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("logo", {
                          validate: async (value) => {
                            if (typeof value !== "string") {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name?.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 1296  && sizes.height !== 388) {
                                return "Image width and height must be 1296 px and 388 px";
                              }

                              // const fileSize = Math.round(
                              //   value[0].size / 1024
                              // );
                              // if (fileSize > 500) {
                              //   return "file size must be lower than 500kb";
                              // }
                            }
                          },
                        })}
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                      />
                      {/* <Form.Control
                        type="file"
                        name="logo"
                        placeholder="Logo"
                        className={classNames("", {
                          "is-invalid": errors?.logo,
                        })}
                        {...register("logo", {
                          required: "logo is required",
                        })}
                        accept="image/*"
                        onChange={handleImageChange}
                      /> */}
                    </InputGroup>
                    {errors.logo && (
                      <span className="text-danger">{errors.logo.message}</span>
                    )}
                  </Form.Group>
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="Category Preview"
                        style={{ maxWidth: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
