import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal, Card } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/product-gift-wrap_price`);
    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const response = await postData(`/masters/product-gift-wrap_price`, data);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        // props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    fields: GiftWrapPriceFields,
    append: GiftWrapPriceAddOn,
    remove: removeGiftWrapPrice,
  } = useFieldArray({
    control,
    name: "gift_wrap_price",
  });
  const [CancellationPolicyError, setCancellationPolicyError] = useState("");

  useEffect(() => {
    register("gift_wrap_price", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setCancellationPolicyError(
          isValid ? "" : "At least one gift wrap price is required"
        );
        return isValid;
      },
    });
  }, [register]);
  const handleKeyPress = (event) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault(); // Prevent negative sign and scientific notation
    }
  };
  return (
    <>
      {/* <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Cloth Size
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
          <Row className="justify-content-center">
              <div className="main-form-section mt-5"></div>
              <Card.Title>
              Gift Wrap Price
                {CancellationPolicyError && (
                  <div className="text-danger">{CancellationPolicyError}</div>
                )}
              </Card.Title>
              <hr />

              {GiftWrapPriceFields.map((val, index) => (
                <div key={val.id} className="main-form-section mt-3">
                  <Row>
                    <Col md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Start Price</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                               
                                type="number"
                                name={`gift_wrap_price.${index}.start_price`}
                                placeholder="Start price"
                               
                                className={classNames("", {
                                  "is-invalid":
                                    errors?.gift_wrap_price?.[index]
                                      ?.start_price,
                                })}
                                {...register(
                                  `gift_wrap_price.${index}.start_price`,
                                  {
                                    required: "Start price is required",
                                    
                                    // validate: (value) =>
                                    //   value > 0 ||
                                    //   "Start price must be greater than 0",
                                  }
                                )}
                                onKeyPress={handleKeyPress}
                              />
                            </InputGroup>
                            {errors?.gift_wrap_price?.[index]
                              ?.start_price && (
                              <span className="text-danger">
                                {
                                  errors?.gift_wrap_price?.[index]
                                    ?.start_price.message
                                }
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>End Price</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                               
                                type="number"
                                name={`gift_wrap_price.${index}.end_price`}
                                placeholder="End price"
                                min="0" // Ensure no negative values are allowed
                                className={classNames("", {
                                  "is-invalid":
                                    errors?.gift_wrap_price?.[index]
                                      ?.end_price,
                                })}
                                {...register(
                                  `gift_wrap_price.${index}.end_price`,
                                  {
                                    required: "End price is required",
                                    // validate: (value) =>
                                    //   value > 0 ||
                                    //   "Start price must be greater than 0",
                                  }
                                )}
                                onKeyPress={handleKeyPress}
                              />
                            </InputGroup>
                            {errors?.gift_wrap_price?.[index]?.end_price && (
                              <span className="text-danger">
                                {
                                  errors?.gift_wrap_price?.[index]?.end_price
                                    .message
                                }
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Price</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                              
                                type="number"
                                name={`gift_wrap_price.${index}.price`}
                                placeholder="price"
                                min="0" // Ensure no negative values are allowed
                                className={classNames("", {
                                  "is-invalid":
                                    errors?.gift_wrap_price?.[index]
                                      ?.price,
                                })}
                                {...register(
                                  `gift_wrap_price.${index}.price`,
                                  {
                                    required: "End price is required",
                                    // validate: (value) =>
                                    //   value > 0 ||
                                    //   "Start price must be greater than 0",
                                  }
                                )}
                                onKeyPress={handleKeyPress}
                              />
                            </InputGroup>
                            {errors?.gift_wrap_price?.[index]
                              ?.price && (
                              <span className="text-danger">
                                {
                                  errors?.gift_wrap_price?.[index]
                                    ?.price.message
                                }
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <button
                        className="btn btn-danger mt-3"
                        type="button"
                        onClick={() => removeGiftWrapPrice(index)}
                      >
                        Remove Gift Wrap Price
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}

              <div className="main-form-section mt-3"></div>
              <hr />
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => GiftWrapPriceAddOn({})}
                >
                  + Add Gift Wrap Price
                </button>
              </div>
              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        {/* </Modal.Body>
      </Modal> */}

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
